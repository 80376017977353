import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import ShareLinksContainer from './containers/ShareLinksContainer';

const getLinkId = (containerElement: HTMLElement) => {
  return containerElement.getAttribute('data-link-id');
};

const getLinkType = (containerElement: HTMLElement) => {
  return containerElement.getAttribute('data-link-type');
};

ready(() => {
  const shareLinksContainer = document.getElementById('share-links-container');

  if (shareLinksContainer) {
    render(
      <ShareLinksContainer
        linkId={getLinkId(shareLinksContainer)}
        linkType={getLinkType(shareLinksContainer)}
      />,
      shareLinksContainer
    );
  }
});
