import React, { useEffect } from 'react';
import { DeepLinkService, ShareLinksType } from 'Roblox';
import { Loading } from 'react-style-guide';
import { authenticatedUser } from 'header-scripts';
import { urlService } from 'core-utilities';

type TShareLinksContainerProps = {
  linkId: string | null;
  linkType: string | null;
};

const ShareLinksContainer = ({ linkId, linkType }: TShareLinksContainerProps): JSX.Element => {
  useEffect(() => {
    if (authenticatedUser.isAuthenticated || linkType === ShareLinksType.EXPERIENCE_AFFILIATE) {
      const navigateToDeepLink = async () => {
        try {
          if (linkId && linkType) {
            const result = await DeepLinkService.navigateToDeepLink(
              `roblox://navigation/share_links?code=${linkId}&type=${linkType}`
            );
            if (!result) {
              throw new Error('Unable to process share link.');
            }
          } else {
            throw new Error('Missing parameters for share link.');
          }
        } catch {
          window.location.href = urlService.getAbsoluteUrl(`/request-error?code=400`);
        }
      };
      // eslint-disable-next-line no-void
      void navigateToDeepLink();
    } else {
      // Redirect to the login page because resolving the endpoint requires an authenticated user. We do
      // not do this in `web-platform` since we want to show a link preview.
      const returnPath = encodeURIComponent(
        `${window.location.protocol}//${window.location.hostname}${window.location.pathname}${window.location.search}`
      );
      window.location.href = urlService.getAbsoluteUrl(`/login?returnUrl=${returnPath}`);
    }
  }, [linkId, linkType]);

  return <Loading />;
};

export default ShareLinksContainer;
